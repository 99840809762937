import React from "react";
import { useTable } from "react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const StyledTable = (props) => {
  const { columns, data, handleSelectRow } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const rowProps = (row) => ({
    ...row.getRowProps(),
    onClick: () => handleSelectRow && handleSelectRow(row.original),
    style: {
      cursor: handleSelectRow ? "pointer" : "default",
      backgroundColor: "white", // Set row background color
      "&:hover": {
        backgroundColor: "#f5f5f5", // Row hover effect
      },
    },
  });

  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: "500px", overflow: "auto" }}
    >
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...rowProps(row)}>
                {row.cells.map((cell) => (
                  <TableCell {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StyledTable;
