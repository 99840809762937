import { Button, ButtonBase, Paper } from "@mui/material";

import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBusiness, getBusinesses } from "../models/business/businessSlice";
import useIsMobile from "../hooks/useIsMobile";
import StyledTable from "../components/StyledTable";
import { businessColumns } from "../config/columns";

const AdminHome = (props) => {
  const isMobile = useIsMobile();

  const { user } = props;
  const isAdmin = sessionStorage.getItem("role") === "admin";
  const userBusinessId = sessionStorage.getItem("businessId");

  const businessSlice = useSelector((state) => state.business);

  const { businesses } = businessSlice;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinesses());
  }, []);

  const handleRowSelect = (row) => {
    console.log("Selected row:", row);
    navigate(`/business/${row?.businessId}`);
  };

  console.log("user: ", user);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "2em",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
        <h2>Businesses</h2>

        <Button variant="outlined" onClick={() => navigate(`/businesses`)}>
          Add new business
        </Button>
      </div>

      <div
        style={{
          marginTop: "2em",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <StyledTable
          columns={businessColumns}
          data={businesses}
          handleSelectRow={handleRowSelect}
        />
      </div>
    </div>
  );
};

export default AdminHome;
