import { Paper } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import CreateBusinessForm from "../components/Form/CreateBusinessForm";
import { useDispatch, useSelector } from "react-redux";
import {
  createBusiness,
  getBusinesses,
} from "../models/business/businessSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { businessColumns } from "../config/columns";
import StyledTable from "../components/StyledTable";
import { useNavigate } from "react-router-dom";

const Businesses = (props) => {
  const { user } = props;

  const { control, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch();

  const [business, setBusiness] = useState({
    businessName: "",
    phoneNumber: "",

    fullAddress: {
      address1: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  const businessSlice = useSelector((state) => state.business);

  const { businesses } = businessSlice;

  useEffect(() => {
    dispatch(getBusinesses());
  }, []);

  const handleCreateBusiness = async () => {
    dispatch(createBusiness(business))
      .then(unwrapResult)
      .then(() => {
        toast.success("Business successfully created");
      })
      .catch(() => {});
  };

  console.log("user: ", user);

  const handleRowSelect = (row) => {
    console.log("Selected row:", row);
    navigate(`/business/${row?.businessId}`);
  };

  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: "2em",
        }}
      >
        <div>
          <h2>Create a business</h2>
          <CreateBusinessForm
            control={control}
            setValue={setValue}
            handleSubmit={handleSubmit}
            handleCreateBusiness={handleCreateBusiness}
            business={business}
            setBusiness={setBusiness}
          />
        </div>
        <div
          style={{
            marginTop: "2em",
            display: "flex",
          }}
        >
          <StyledTable
            columns={businessColumns}
            data={businesses}
            handleSelectRow={handleRowSelect}
          />
        </div>
      </div>
    </div>
  );
};

export default Businesses;
